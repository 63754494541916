<template>
  <div id="teomp" class="h-90">
    <!--begin::Compose-->

    <b-row>
      <b-col lg="1" order-lg="1"></b-col>
      <b-col lg="6" order-lg="6">
        <ComposeWidget></ComposeWidget>
      </b-col>
      <b-col lg="1" order-lg="7"></b-col>
      <b-col lg="3" order-lg="9">
        <RecipientSelectorWidget></RecipientSelectorWidget>
      </b-col>
      <b-col lg="1" order-lg="12"></b-col>
      <!-- <b-col lg="3" order-lg="1">
        <TemplateSelectorWidget></TemplateSelectorWidget>
      </b-col> -->
    </b-row>

    <!--end::Compose-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RecipientSelectorWidget from "@/view/content/widgets/compose/RecipientSelectorWidget.vue";
//import TemplateSelectorWidget from "@/view/content/widgets/compose/TemplateSelectorWidget.vue";
import ComposeWidget from "@/view/content/widgets/compose/ComposeWidget.vue";

export default {
  name: "compose",
  components: {
    RecipientSelectorWidget,
    // TemplateSelectorWidget,
    ComposeWidget,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Compose" }]);
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
};
</script>
