<template>
  <div
    class="card card-custom bg-light-success gutter-b card-shadowless"
    id="recipientContainer"
  >
    <!--begin::Header-->
    <div class="card-header border-0">
      <h3 class="mt-5 card-title font-weight-bolder text-success">Contacts</h3>
      <!-- <div class="card-toolbar"> -->
      <!-- <Dropdown5></Dropdown5> -->
      <!-- </div> -->
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2">
      <b-input-group class="w-100">
        <b-form-input
          class="form-control"
          v-model="search"
          id="recipient-search"
          type="search"
          placeholder="Type to search..."
          @input="searchRecipients"
        ></b-form-input>
        <b-input-group-append>
          <b-button :disabled="!search" @click="clearFilter()" variant="primary"
            >Clear</b-button
          >
        </b-input-group-append>
      </b-input-group>
    </div>

    <perfect-scrollbar class="scroll" :style="scrollbarStyle">
      <div class="card-body pt-2">
        <template v-for="(item, i) in filteredContacts">
          <!--begin::Item-->
          <div class="d-flex align-items-center mb-10" v-bind:key="i">
            <!--begin::Symbol-->
            <div class="symbol symbol-40 symbol-light-white mr-5">
              <span class="symbol-label">
                <inline-svg
                  :src="avatar(item)"
                  style
                  class="h-50 align-self-center"
                ></inline-svg>
              </span>
            </div>
            <!--end::Symbol-->
            <!--begin::Text-->
            <div class="d-flex flex-column flex-grow-1 font-weight-bold">
              <a
                @click="clicked(item)"
                href="#"
                class="text-dark text-hover-primary mb-1 font-size-lg"
              >
                {{ item.name }}
              </a>
              <span class="text-muted">
                {{ item.description }}
              </span>
            </div>
            <!--end::Text-->
          </div>
          <!--end::Item-->
        </template>
      </div>
    </perfect-scrollbar>
    <!--end::Body-->
  </div>
</template>

<script>
// import Dropdown5 from "@/view/content/dropdown/Dropdown5.vue";
import ApiService from "@/core/services/api.service";

export default {
  name: "RecipientSelectorWidget",
  components: {
    // Dropdown5,
  },

  // *************************** Data *************************************
  data() {
    return {
      scrollbarStyle: {
        maxHeight: "0px",
        position: "relative",
      },
      contacts: [],
      filteredContacts: [],
      search: null,
    };
  },

  // *************************** Computed **********************************
  computed: {
    windowHeight() {
      return (
        Math.max(
          document.documentElement.clientHeight || 0,
          window.innerHeight || 0
        ) + "px"
      );
    },

    containerHeight() {
      return (
        Math.max(
          document.documentElement.clientHeight || 0,
          window.innerHeight || 0
        ) -
        350 +
        "px"
      );
    },
  },

  // *************************** Methods **********************************
  methods: {
    clicked(item) {
      if (!this.$store.getters.recipientKeys.includes(item.itemKey)) {
        this.$store.commit("addRecipient", {
          key: item.itemKey,
          caption: item.name,
          description: item.description,
        });
      }
    },

    avatar(contact) {
      if (contact.description == "list") {
        return "media/svg/avatars/avatar-group-orange.svg";
      } else {
        return "media/svg/avatars/avatar-single-orange.svg";
      }
    },

    searchRecipients() {
      if (this.search) {
        var filter = this.search.toLowerCase();
        this.filteredContacts = this.contacts.filter(
          (x) =>
            x.name.toLowerCase().includes(filter) ||
            x.description.toLowerCase().includes(filter)
        );
      } else {
        this.filteredContacts = this.contacts.sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
      }
    },

    clearFilter() {
      this.search = null;
      this.searchRecipients();
    },
  },

  // *************************** Mounted() **********************************
  mounted() {
    this.scrollbarStyle.maxHeight = this.containerHeight;

    ApiService.query("contactsAndListsFromView").then((response) => {
      this.contacts = response.data;
      this.searchRecipients();
    });
  },
};
</script>
