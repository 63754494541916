<template>
  <div>
    <label for="tags-basic">To:</label>
    <b-form-tags
      :value="recipients"
      @input="handleRecipientInput"
      duplicate-tag-text="This mobile number is already in the recipient list"
      invalid-tag-text="Please enter a valid Australian mobile number"
      :tag-validator="validator"
      no-outer-focus
      class="mb-2"
    >
      <template
        v-slot="{
          tags,
          addTag,
          inputAttrs,
          inputHandlers,
          tagVariant,
          removeTag,
          duplicateTagText,
          isDuplicate,
          invalidTagText,
          isInvalid,
        }"
      >
        <div class="d-inline-block" style="font-size: 1.5rem;">
          <b-form-tag
            v-for="tag in tags"
            @remove="removeTag(tag)"
            :key="tag"
            :title="tag"
            :variant="tagVariant"
            class="mr-2, mb-4"
            >{{ tag }}
          </b-form-tag>
        </div>
        <b-input-group>
          <b-form-input
            v-bind="inputAttrs"
            v-on="inputHandlers"
            :placeholder="
              isMobile ? 'New recipient' : 'New recipient - press enter to add'
            "
            class="form-control"
            :style="{ fontSize: isMobile ? '16px' : '1rem' }"
            :formatter="formatter"
          ></b-form-input>
          <b-input-group-append>
            <b-button
              @click="addTag"
              :disabled="isInvalid || !inputAttrs.value || isDuplicate"
              variant="primary"
              >Add</b-button
            >
            <b-button
              @click="
                clearRecipients();
                inputAttrs.value = null;
              "
              variant="primary"
              >Clear</b-button
            >
          </b-input-group-append>
        </b-input-group>
        <p class="mt-2" v-if="isDuplicate">{{ duplicateTagText }}</p>
        <p class="mt-2" v-if="isInvalid">{{ invalidTagText }}</p>
      </template>
    </b-form-tags>
    <b-form @submit="onSubmit" @reset="onReset">
      <b-form-group
        id="input-group-from"
        label="From:"
        label-for="from-select"
        class="mt-8"
      >
        <b-form-select
          id="from-select"
          v-model="form.sender"
          value-field="id"
          text-field="caption"
          :options="options"
          :style="{ fontSize: isMobile ? '16px' : '1rem' }"
        ></b-form-select>
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Message:"
        label-for="textarea-1"
        class="mt-8"
      >
        <b-form-textarea
          id="textarea-1"
          v-model="form.text"
          placeholder="Type your message here"
          rows="12"
          max-rows="6"
          :style="{ fontSize: isMobile ? '16px' : '1rem' }"
        ></b-form-textarea>
        <p
          class="form-text"
          :class="{
            'text-muted': !charCountExceeded,
            'text-warning': charCountExceeded,
          }"
        >
          {{ form.text.length }} characters.
        </p>
        <p
          v-if="charCountExceeded"
          class="form-text text-warning font-weight-bold"
        >
          Messages over {{ charLimit }} characters will be sent as multiple SMS
          messages and incur extra charges
        </p>
      </b-form-group>
    </b-form>

    <b-row v-if="!(isSending || isSent || error)" align-h="end" class="mb-12">
      <b-col lg="4">
        <b-button
          :disabled="!isMessageReadyToSend"
          block
          variant="primary"
          @click="sendMessageClicked"
          >Send message</b-button
        >
      </b-col>
    </b-row>

    <b-row v-if="isSending" align-h="end" class="mb-12">
      <b-col lg="12" class="text-right">
        <p>
          Message sending...
        </p>
      </b-col>
    </b-row>

    <b-row v-if="isSent" align-h="end" class="mb-4">
      <b-col lg="12" class="text-right">
        <p>
          Message sent.
        </p>
      </b-col>
    </b-row>

    <b-row v-if="error" align-h="end" class="font-weight-bold text-danger mb-4">
      <b-col lg="12" class="text-right">
        <p>
          {{ error }}
        </p>
      </b-col>
    </b-row>

    <b-row v-if="isSent || error" align-h="end" class="mb-12">
      <b-col lg="12" class="text-right">
        <p>
          <b-link @click="resetSendButton"
            >Click here to re-enable the Send button</b-link
          >
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  SET_RECIPIENTS,
  ADD_RECIPIENT,
  REMOVE_RECIPIENT,
} from "@/core/services/store/recipients.module";
import ApiService from "@/core/services/api.service";
import KTUtil from "@/assets/js/components/util";

export default {
  name: "ComposeWidget",
  components: {},

  data() {
    return {
      isSending: false,
      isSent: false,
      error: null,
      isMobile: this.isMobileComputed,
      charLimit: 160,
      form: {
        email: "",
        name: "",
        food: null,
        checked: [],
        text: "",
        selected: null,
        sender: null,
        hasRecipients: false,
        // recipients: [],
      },
      options: [],
    };
  },

  computed: {
    recipients() {
      return this.$store.getters.recipientCaptions;
    },

    charCountExceeded() {
      return this.form.text.length > this.charLimit;
    },

    isMobileComputed() {
      return this.checkIfMobile();
    },

    // returns true when the message passes validation and is ready to send
    isMessageReadyToSend() {
      return this.recipients.length > 0 && this.form.sender && this.form.text;
    },
  },

  methods: {
    handleRecipientInput(evt) {
      // we loop through the captions and add a manual recipient for the missing one(s)
      evt.forEach((x) => {
        if (!this.$store.getters.recipientCaptions.includes(x)) {
          this.addManualRecipient(x);
        }
      });

      // we also do it the other way around to remove any removed items
      this.$store.getters.recipientCaptions.forEach((x) => {
        if (!evt.includes(x)) {
          this.removeRecipient(x);
        }
      });
    },

    onSubmit(evt) {
      evt.preventDefault();
      alert(JSON.stringify(this.form));
    },

    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    formatter(value) {
      let digits = value.replace(/\D/g, "");
      var result = digits;

      if (
        digits.length > 0 &&
        digits.charAt(0) !== "0" &&
        digits.charAt(0) !== "4" &&
        digits.charAt(1) !== "4"
      ) {
        result = "04" + digits.substring(0, digits.length);
      } else if (digits.length > 0 && digits.charAt(0) !== "0") {
        result = "0" + digits.substring(0, digits.length);
      }

      if (digits.length > 7) {
        result =
          digits.substring(0, 4) +
          " " +
          digits.substring(4, 7) +
          " " +
          digits.substring(7, 10);
      } else if (digits.length > 4) {
        result =
          digits.substring(0, 4) + " " + digits.substring(4, digits.length);
      }

      return result;
    },

    validator(tag) {
      var phoneno = /^04\d{8}$/;

      return tag.replace(/\D/g, "").match(phoneno);
    },

    addRecipient(recipient) {
      this.$store.commit("addRecipient", recipient);
    },

    addManualRecipient(mobileNumber) {
      this.$store.commit("addRecipient", {
        key: "M" + mobileNumber.replace(/\D/g, ""),
        caption: mobileNumber,
        description: mobileNumber,
      });
    },

    removeRecipient(recipient) {
      this.$store.commit("removeRecipient", recipient);
    },

    setRecipients(recipients) {
      this.$store.commit("setRecipients", recipients);
    },

    clearRecipients() {
      this.$store.commit("clearRecipients");
    },

    checkIfMobile() {
      return window.innerWidth < 992;
    },

    updateIsMobile() {
      this.isMobile = this.checkIfMobile();
    },

    sendMessageClickedWithDelay() {
      this.isSending = true;
      console.log("send message clicked");
      window.setTimeout(this.sendMessageClicked, 3000);
    },

    sendMessageClicked() {
      let message = {
        text: this.form.text,
        recipients: this.$store.getters.recipients,
        senderId: this.form.sender,
      };
      console.log(JSON.stringify(this.$store.getters.recipients));

      console.log(JSON.stringify(message));

      ApiService.post("messages", message)
        .then((response) => {
          if (response.status == 200) {
            this.isSending = false;
            this.messageSent(response.data);
          } else {
            this.isSending = false;
            this.error = "Message failed to send.";
            this.$bvToast.toast("Error", {
              title: "Message failed to send",
              variant: "danger",
              solid: true,
            });
          }
        })
        .catch((error) => {
          console.log("Error! " + JSON.stringify(error.message));
          this.isSending = false;
          this.error =
            "Message failed to send.  If this problem persists, please contact your support desk.";
          this.$bvToast.toast(
            "There was a problem sending your message.  If this problem persists, please contact your support desk.",
            {
              title: "Okay, Houston, we've had a problem here :(",
              variant: "danger",
              solid: true,
            }
          );
        });
    },
    messageSent(response) {
      console.log(`Message sent: ${JSON.stringify(response)}`);
      this.isSent = true;
    },

    resetSendButton() {
      console.log("resetting button");
      this.isSent = false;
      this.error = null;
    },
  },

  mounted() {
    this.updateIsMobile();
    // add event listener to detect mobile view
    window.addEventListener("resize", this.updateIsMobile);

    ApiService.query("senders").then((response) => {
      this.options = response.data;
      if (this.options.length > 0) {
        this.form.sender = this.options[0].id;
      }
    });
  },

  beforeDestroy() {
    // remove event listeners
    window.removeEventListener("resize", this.updateIsMobile);
  },
};
</script>
