var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('label',{attrs:{"for":"tags-basic"}},[_vm._v("To:")]),_c('b-form-tags',{staticClass:"mb-2",attrs:{"value":_vm.recipients,"duplicate-tag-text":"This mobile number is already in the recipient list","invalid-tag-text":"Please enter a valid Australian mobile number","tag-validator":_vm.validator,"no-outer-focus":""},on:{"input":_vm.handleRecipientInput},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var tags = ref.tags;
      var addTag = ref.addTag;
      var inputAttrs = ref.inputAttrs;
      var inputHandlers = ref.inputHandlers;
      var tagVariant = ref.tagVariant;
      var removeTag = ref.removeTag;
      var duplicateTagText = ref.duplicateTagText;
      var isDuplicate = ref.isDuplicate;
      var invalidTagText = ref.invalidTagText;
      var isInvalid = ref.isInvalid;
return [_c('div',{staticClass:"d-inline-block",staticStyle:{"font-size":"1.5rem"}},_vm._l((tags),function(tag){return _c('b-form-tag',{key:tag,staticClass:"mr-2, mb-4",attrs:{"title":tag,"variant":tagVariant},on:{"remove":function($event){return removeTag(tag)}}},[_vm._v(_vm._s(tag)+" ")])}),1),_c('b-input-group',[_c('b-form-input',_vm._g(_vm._b({staticClass:"form-control",style:({ fontSize: _vm.isMobile ? '16px' : '1rem' }),attrs:{"placeholder":_vm.isMobile ? 'New recipient' : 'New recipient - press enter to add',"formatter":_vm.formatter}},'b-form-input',inputAttrs,false),inputHandlers)),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":isInvalid || !inputAttrs.value || isDuplicate,"variant":"primary"},on:{"click":addTag}},[_vm._v("Add")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){_vm.clearRecipients();
              inputAttrs.value = null;}}},[_vm._v("Clear")])],1)],1),(isDuplicate)?_c('p',{staticClass:"mt-2"},[_vm._v(_vm._s(duplicateTagText))]):_vm._e(),(isInvalid)?_c('p',{staticClass:"mt-2"},[_vm._v(_vm._s(invalidTagText))]):_vm._e()]}}])}),_c('b-form',{on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('b-form-group',{staticClass:"mt-8",attrs:{"id":"input-group-from","label":"From:","label-for":"from-select"}},[_c('b-form-select',{style:({ fontSize: _vm.isMobile ? '16px' : '1rem' }),attrs:{"id":"from-select","value-field":"id","text-field":"caption","options":_vm.options},model:{value:(_vm.form.sender),callback:function ($$v) {_vm.$set(_vm.form, "sender", $$v)},expression:"form.sender"}})],1),_c('b-form-group',{staticClass:"mt-8",attrs:{"id":"input-group-1","label":"Message:","label-for":"textarea-1"}},[_c('b-form-textarea',{style:({ fontSize: _vm.isMobile ? '16px' : '1rem' }),attrs:{"id":"textarea-1","placeholder":"Type your message here","rows":"12","max-rows":"6"},model:{value:(_vm.form.text),callback:function ($$v) {_vm.$set(_vm.form, "text", $$v)},expression:"form.text"}}),_c('p',{staticClass:"form-text",class:{
          'text-muted': !_vm.charCountExceeded,
          'text-warning': _vm.charCountExceeded,
        }},[_vm._v(" "+_vm._s(_vm.form.text.length)+" characters. ")]),(_vm.charCountExceeded)?_c('p',{staticClass:"form-text text-warning font-weight-bold"},[_vm._v(" Messages over "+_vm._s(_vm.charLimit)+" characters will be sent as multiple SMS messages and incur extra charges ")]):_vm._e()],1)],1),(!(_vm.isSending || _vm.isSent || _vm.error))?_c('b-row',{staticClass:"mb-12",attrs:{"align-h":"end"}},[_c('b-col',{attrs:{"lg":"4"}},[_c('b-button',{attrs:{"disabled":!_vm.isMessageReadyToSend,"block":"","variant":"primary"},on:{"click":_vm.sendMessageClicked}},[_vm._v("Send message")])],1)],1):_vm._e(),(_vm.isSending)?_c('b-row',{staticClass:"mb-12",attrs:{"align-h":"end"}},[_c('b-col',{staticClass:"text-right",attrs:{"lg":"12"}},[_c('p',[_vm._v(" Message sending... ")])])],1):_vm._e(),(_vm.isSent)?_c('b-row',{staticClass:"mb-4",attrs:{"align-h":"end"}},[_c('b-col',{staticClass:"text-right",attrs:{"lg":"12"}},[_c('p',[_vm._v(" Message sent. ")])])],1):_vm._e(),(_vm.error)?_c('b-row',{staticClass:"font-weight-bold text-danger mb-4",attrs:{"align-h":"end"}},[_c('b-col',{staticClass:"text-right",attrs:{"lg":"12"}},[_c('p',[_vm._v(" "+_vm._s(_vm.error)+" ")])])],1):_vm._e(),(_vm.isSent || _vm.error)?_c('b-row',{staticClass:"mb-12",attrs:{"align-h":"end"}},[_c('b-col',{staticClass:"text-right",attrs:{"lg":"12"}},[_c('p',[_c('b-link',{on:{"click":_vm.resetSendButton}},[_vm._v("Click here to re-enable the Send button")])],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }